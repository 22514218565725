<script>
	export default {
		name: "SwaggerDocManage",
		data: function () {
			return {
				url: ""
			}
		},
		mounted() {
			this.url = this.$route.params.url;
		}
	}
</script>

<style scoped>

</style>
